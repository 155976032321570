import { createBrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./components";
import SDKEntryPoint from "./views/SDKEntryPoint";
import MicroFlows from "./microflows";
import KYCMicroflow from "./microflows/KYC";
import { LoanOfferMicroflow } from "./microflows/LoanOffer";
import NotFound from "./microflows/NotFound";
import TimeoutScreen from "./components/TimeoutScreen/TimeoutScreen";

const router = createBrowserRouter([
  {
    path: "/microflow",
    element: <MicroFlows />,
    errorElement: <ErrorBoundary showHeader />,
    children: [
      {
        path: "/microflow/kyc",
        element: <KYCMicroflow />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/microflow/offer",
        element: <LoanOfferMicroflow />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/microflow/",
        element: <NotFound />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    path: "/timeout",
    element: <TimeoutScreen />,
  },
  {
    path: "/",
    element: <SDKEntryPoint />,
    errorElement: <ErrorBoundary showHeader />,
  },
]);

export default router;
