import { FC, useEffect, useState } from "react";
import { Button, Divider, InputField, Slider } from "../../components";
import { useLoanApplication, useLoanCalculator } from "../../contexts";
import { useFormik } from "formik";
import {
  FixedTermCalculatorFields,
  getFixedTermLoanValidationSchema,
} from "./service";
import {
  LoanApplicationStage,
  LoanDurationType,
  LoanProductType,
} from "../../hooks/api/types";
import { roundTo } from "../../utils";
import { ArrowLeftIcon } from "../../assets/icons";
import { useLoanMetrics } from "../../hooks";
import TermsAndConditions from "./TermsAndConditions";
import { useDpConfig } from "../../contexts/dpConfig";
import { useStepper } from "../LoanApplicationSteps/Stepper";

type Props = {
  editMode?: boolean;
  handleSubmit?: () => void;
  handleCancel?: () => void;
};

const FixedTermLoanCalculator: FC<Props> = ({
  editMode = false,
  handleSubmit: handleSubmitFromParent,
  handleCancel: handleCancelFromParent,
}) => {
  const [isPartnerTermsAccepted, setIsPartnerTermsAccepted] =
    useState<boolean>(false);
  const {
    state: { fixedTermLoan, constraints },
    actions: { setFixedTermLoan },
  } = useLoanCalculator();
  const {
    state: { dpTermsUrl },
  } = useDpConfig();
  const {
    state: { id, sourceId },
    actions: { update },
  } = useLoanApplication();
  const { monthlyBusinessLoanPaymentForSME } = useLoanMetrics();
  const {
    state: { settings },
  } = useDpConfig();
  const { nextStep } = useStepper();

  const { values, errors, setFieldValue, handleSubmit } =
    useFormik<FixedTermCalculatorFields>({
      enableReinitialize: true,
      initialValues: {
        // ...LOAN_FIXED_TERM_INITIAL_VALUES,
        loanAmount:
          fixedTermLoan?.amount || constraints.fixedTermLoan?.amount?.initial,
        duration:
          fixedTermLoan?.duration ||
          constraints.fixedTermLoan?.duration?.initial,
      },
      validationSchema: getFixedTermLoanValidationSchema({
        loanAmount: {
          min: constraints.fixedTermLoan?.amount?.min,
          max: constraints.fixedTermLoan?.amount?.max,
          unit: constraints.fixedTermLoan?.amount?.unit ?? "AED",
        },
        duration: {
          min: constraints.fixedTermLoan?.duration?.min,
          max: constraints.fixedTermLoan?.duration?.max,
        },
      }),
      onSubmit: async () => {
        setFixedTermLoan({
          amount: values.loanAmount,
          duration: values.duration,
          interestRate: constraints.fixedTermLoan.interestRate,
          durationType: constraints.fixedTermLoan.duration
            .unit as LoanDurationType,
        });
        await update(
          {
            id,
            loanType: LoanProductType.FIXED_TERM,
            fixedTermLoan: fixedTermLoan,
          },
          { local: settings.disableLOCFlow }
        );

        // if LOC flow is disabled, skip to the next step
        if (settings.disableLOCFlow) {
          // i.e legacy flow is enabled
          // go to next step
          nextStep();
          //if you land on invoice page, skip it
          if (!settings.disableInvoiceUpload) nextStep();
          //if you land on sales statement page, skip it
          if (!settings.disableSalesStatementsUpload) nextStep();
          //if you land on sales invoice page, skip it
          if (!settings.disableSalesInvoiceUpload) nextStep();
        } else if (sourceId) {
          await update({ stage: LoanApplicationStage.REVIEW });
        } else {
          await update({ stage: LoanApplicationStage.OWNER_INFORMATION });
        }
      },
    });

  const handleInputChange = (name: string, value: string) => {
    const num = Number(value.replaceAll(",", ""));
    if (isNaN(num)) {
      return null;
    }

    setFieldValue(name, num, true);

    const validation: any = {
      loanAmount: constraints.fixedTermLoan.amount,
      duration: constraints.fixedTermLoan.duration,
    };

    if (num > validation[name].max || num < validation[name].min) {
      return null;
    }

    return num;
  };

  const handleLoanAmountInputChange = (value: string) => {
    const val = handleInputChange("loanAmount", value);
    if (val !== null) {
      setFixedTermLoan({
        ...fixedTermLoan,
        amount: val,
        interestRate: constraints.fixedTermLoan.interestRate,
      });
    }
  };

  const handleLoanAmountSliderChange = (value: number) => {
    setFieldValue("loanAmount", value);
    setFixedTermLoan({
      ...fixedTermLoan,
      amount: value,
      interestRate: constraints.fixedTermLoan.interestRate,
    });
  };

  const handleDurationInputChange = (value: string) => {
    const val = handleInputChange("duration", value);
    if (val !== null) {
      setFixedTermLoan({
        ...fixedTermLoan,
        duration: val,
        interestRate: constraints.fixedTermLoan.interestRate,
      });
    }
  };

  const handleDurationSliderChange = (value: number) => {
    setFieldValue("duration", value);
    setFixedTermLoan({
      ...fixedTermLoan,
      duration: value,
      interestRate: constraints.fixedTermLoan.interestRate,
    });
  };

  const handleTermsChange = (checked: boolean) => {
    setIsPartnerTermsAccepted(checked);
  };

  //if fixedTermLoan is empty, set it to the initial values
  useEffect(() => {
    if (
      !fixedTermLoan.amount &&
      !fixedTermLoan.duration &&
      !fixedTermLoan.interestRate
    ) {
      setFixedTermLoan({
        amount: constraints.fixedTermLoan?.amount?.initial,
        duration: constraints.fixedTermLoan?.duration?.initial,
        interestRate: constraints.fixedTermLoan?.interestRate,
        durationType: constraints.fixedTermLoan?.duration
          ?.unit as LoanDurationType,
      });
    }
  }, [fixedTermLoan]);

  return (
    <div className="cx-w-full cx-flex cx-flex-col cx-p-10 cx-gap-10 cx-items-center cx-rounded-modal cx-border-0.5 cx-border-[#DDD] cx-shadow-modal cx-background-calculator-gradient">
      <div className="cx-flex cx-flex-col cx-items-center cx-w-full">
        <div className="cx-font-medium">Your estimated monthly installment</div>
        <div className="cx-text-3xl cx-font-bold">
          {constraints.fixedTermLoan?.amount?.unit}{" "}
          {roundTo(monthlyBusinessLoanPaymentForSME, 2).toLocaleString()}
        </div>
        <div className="cx-text-sm cx-text-text-secondary">
          Pay as low as {roundTo((fixedTermLoan?.interestRate / 12) * 100, 2)}%
          monthly interest*.
        </div>
      </div>
      <Divider />
      <div className="cx-flex cx-flex-row cx-w-full cx-gap-6">
        <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
          <div className="cx-font-semibold">Loan amount</div>
          <div>
            <InputField
              inputProps={{
                name: "loanAmount",
                value: values.loanAmount?.toLocaleString(),
                onChange: (e) => handleLoanAmountInputChange(e.target.value),
              }}
              inputClassName="cx-text-right !cx-p-2 cx-font-bold"
              prepend={constraints.fixedTermLoan?.amount?.unit}
              error={errors.loanAmount}
              hideErrorMessage
            />
          </div>
          <div className="">
            <Slider
              initial={values.loanAmount}
              min={constraints.fixedTermLoan?.amount?.min}
              max={constraints.fixedTermLoan?.amount?.max}
              unit={constraints.fixedTermLoan?.amount?.unit}
              value={values.loanAmount}
              label=""
              onChange={(value) => handleLoanAmountSliderChange(value)}
              renderThumb={false}
              interval={1000}
            />
          </div>
          {errors.loanAmount && (
            <div className="cx-text-xs cx-text-text-error">
              {errors.loanAmount}
            </div>
          )}
        </div>
        <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
          <div className="cx-font-semibold">Loan duration</div>
          <div>
            <InputField
              inputProps={{
                name: "duration",
                value: values.duration,
                onChange: (e) => handleDurationInputChange(e.target.value),
              }}
              inputClassName="cx-text-right !cx-p-2 cx-font-bold"
              prepend="Months"
            />
          </div>
          <div className="">
            <Slider
              initial={values.duration}
              min={constraints.fixedTermLoan?.duration?.min}
              max={constraints.fixedTermLoan?.duration?.max}
              unit={constraints.fixedTermLoan?.duration?.unit}
              value={values.duration}
              label=""
              onChange={(value) => handleDurationSliderChange(value)}
              renderThumb={false}
            />
          </div>
          {errors.duration && (
            <div className="cx-text-xs cx-text-text-error">
              {errors.duration}
            </div>
          )}
        </div>
      </div>
      <Divider />
      <div className="cx-w-[400px]">
        {editMode ? (
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <div className="cx-w-1/4">
              <Button
                label={<ArrowLeftIcon />}
                type="button"
                onClick={handleCancelFromParent}
                fullWidth
                outlined
              />
            </div>
            <div className="cx-w-3/4">
              <Button
                type="button"
                label="Confirm"
                fullWidth
                onClick={handleSubmitFromParent}
                disabled={Object.keys(errors).length > 0}
              />
            </div>
          </div>
        ) : (
          <Button
            label="Apply"
            arrow="right"
            fullWidth
            onClick={handleSubmit}
            disabled={
              Object.keys(errors).length > 0 ||
              (!isPartnerTermsAccepted && !!dpTermsUrl)
            }
          />
        )}
      </div>
      <Divider />
      <div className="cx-block">
        <TermsAndConditions onTermsChange={handleTermsChange} />
      </div>
    </div>
  );
};

export default FixedTermLoanCalculator;
