import { createContext, useContext, useMemo, useReducer } from "react";

type MicroFlowContextValues = {
  actions: {
    update: (payload: Partial<MicroFlowContextValues["state"]>) => void;
    reset: () => void;
  };
  state: {
    logo?: string;
    footerLogo?: string;
  };
};

type MicroFlowContextAction =
  | {
      type: "UPDATE";
      payload: Partial<MicroFlowContextValues["state"]>;
    }
  | {
      type: "RESET";
    };

const INITIAL_STATE: MicroFlowContextValues["state"] = {
  logo: undefined,
};

const MicroFlowContext = createContext<MicroFlowContextValues>({
  actions: {
    update: () => {},
    reset: () => {},
  },
  state: INITIAL_STATE,
});

const reducer = (
  state: MicroFlowContextValues["state"],
  action: MicroFlowContextAction
): MicroFlowContextValues["state"] => {
  if (action.type === "UPDATE") {
    return {
      ...state,
      ...action.payload,
    };
  }
  if (action.type === "RESET") {
    return INITIAL_STATE;
  }
  return state;
};

export const MicroFlowContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const actions = useMemo(() => {
    return {
      update: (payload: Partial<MicroFlowContextValues["state"]>) => {
        dispatch({ type: "UPDATE", payload });
      },
      reset: () => {
        dispatch({ type: "RESET" });
      },
    };
  }, [dispatch]);

  const contextValue = useMemo(() => {
    return { actions, state };
  }, [actions, state]);

  return (
    <MicroFlowContext.Provider value={contextValue}>
      {children}
    </MicroFlowContext.Provider>
  );
};

export const useMicroFlow = () => useContext(MicroFlowContext);
