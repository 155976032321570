import {
  Button,
  CardList,
  Combobox,
  Dropzone,
  Modal,
} from "../../../components";
import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  ALL_DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA,
  AllDocumentsFormFields,
  CustomFile,
} from "./service";
import { useLoanApplication } from "../../../contexts";
import {
  LoanApplicationStage,
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../../hooks/api/types";
import { useDeleteMedia, useMedia } from "../../../hooks";
import { CardVariantType } from "../../../components/CardList/CardList";
import { PdfIcon } from "../../../assets/icons";
import { formatBytes } from "../../../utils";
import { useDpConfig } from "../../../contexts/dpConfig";

type Props = {
  open: boolean;
  onClose: () => void;
  onAddDocument?: () => void;
};

type DocumentPurposeFormFields = {
  value: string;
  label: string;
};
export const DocumentPurpose: Array<DocumentPurposeFormFields> = [
  {
    value: MediaPurpose.MEMORANDUM_OF_ASSOCIATION,
    label: "Memorandum Of Association",
  },
  {
    value: MediaPurpose.POWER_OF_ATTORNEY,
    label: "Power Of Attorney",
  },
  { value: MediaPurpose.BANK_STATEMENT, label: "Bank Statement" },
  { value: MediaPurpose.TRADE_LICENSE, label: "Trade License" },
  { value: MediaPurpose.VAT_STATEMENT, label: "VAT Statement" },
  {
    value: MediaPurpose.GENERIC_DOCUMENT,
    label: "Additional Financial Document",
  },
  { value: MediaPurpose.INVOICE, label: "Sales Invoice" },
];

const mapInvoiceLabelToDocumentPurposes = (
  documentPurposes: DocumentPurposeFormFields[],
  invoiceLabel?: string
): Array<DocumentPurposeFormFields> => {
  const mappedDocuments = documentPurposes.map((doc) =>
    doc.value === MediaPurpose.INVOICE && invoiceLabel
      ? { ...doc, label: invoiceLabel }
      : doc
  );
  return mappedDocuments;
};

const VAT_LIMIT = Number(import.meta.env.VITE_SKIP_VAT_LIMIT ?? 375000);

const AddDocumentModal: FC<Props> = ({ open, onClose, onAddDocument }) => {
  const [allDocuments, setAllDocuments] = useState<CustomFile[]>([]);
  const [documentsIds, setDocumentsIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean[]>([]);
  const [dropZoneError, setDropeZoneError] = useState<boolean>(false);
  const [isFailedUploadExist, setIsFailedUploadExist] =
    useState<boolean>(false);
  const [documentPurposes, setDocumentPurposes] =
    useState<DocumentPurposeFormFields[]>(DocumentPurpose);
  const [customErrors, setCustomErrors] = useState<{ [key: string]: string }>(
    {}
  );

  const {
    state,
    actions: { update, refetch },
  } = useLoanApplication();
  const {
    state: { settings, uiLocalizationData },
  } = useDpConfig();

  const invoiceLabels = mapInvoiceLabelToDocumentPurposes(
    documentPurposes,
    uiLocalizationData.reviewScreen.invoiceLabel
  );

  const { mutateAsync: deleteMediaById } = useDeleteMedia();

  const filterDocumentPurposesBasedOnDpSettings = (
    documentPurposes: DocumentPurposeFormFields[]
  ) => {
    const filters = [
      {
        condition: settings.disableVATStatementsUpload,
        purpose: MediaPurpose.VAT_STATEMENT,
      },
      {
        condition: settings.disableBankStatementsUpload,
        purpose: MediaPurpose.BANK_STATEMENT,
      },
      {
        condition: settings.disableSalesStatementsUpload,
        purpose: MediaPurpose.SALES_STATEMENT,
      },
    ];
    let filteredDocumentPurposes = documentPurposes;

    filters.forEach(({ condition, purpose }) => {
      if (condition) {
        filteredDocumentPurposes = filteredDocumentPurposes.filter(
          (doc) => doc.value !== purpose
        );
      }
    });
    return filteredDocumentPurposes;
  };

  useEffect(() => {
    let filteredDocumentPurposes =
      filterDocumentPurposesBasedOnDpSettings(DocumentPurpose);

    setDocumentPurposes(filteredDocumentPurposes);

    if (state.loanType === LoanProductType.REVENUE_BASED_FINANCING) {
      setDocumentPurposes((prevDocumentPurpose) => {
        if (
          !settings.disableSalesStatementsUpload &&
          !prevDocumentPurpose.find(
            (e) => e.value === MediaPurpose.SALES_STATEMENT
          )
        ) {
          return [
            ...prevDocumentPurpose,
            { value: MediaPurpose.SALES_STATEMENT, label: "Sales Statement" },
          ];
        }
        return prevDocumentPurpose;
      });
    }
  }, [state.loanType]);

  const { upload } = useMedia();

  useEffect(() => {
    let isUploadError = false;
    allDocuments.forEach((document) => {
      if (document.error) isUploadError = true;
    });
    if (!isUploadError) setIsFailedUploadExist(false);
  }, [isFailedUploadExist, allDocuments]);

  useEffect(() => {
    if (!isFailedUploadExist) handleClose();
  }, [isFailedUploadExist]);

  const {
    errors,
    setFieldTouched,
    resetForm,
    handleChange,
    values,
    touched,
    handleSubmit,
    validateForm,
    setFieldValue,
  } = useFormik<AllDocumentsFormFields>({
    initialValues: {
      documents: [],
      mediaPurpose: "",
    },
    validationSchema: ALL_DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      if (!values.documents.length) return;

      setUploading((prev) => {
        let next = [...prev];
        values.documents.forEach((item, index) => {
          next[index] = true;
        });
        return next;
      });
      setLoading(true);
      const documentPromises = values.documents.map(async (file, index) => {
        if (file.id) return;
        return upload({
          file,
          type: MediaType.DOCUMENT,
          purpose: values.mediaPurpose as MediaPurpose,
          loanApplicationId: state.id,
        })
          .then((res) => {
            setDocumentsIds((prev) => {
              let next = [...prev];
              next[next.length] = res;
              return next;
            });
            setAllDocuments((prev) => {
              let next = [...prev];
              next.forEach((document) => {
                if (document.name === file.name) document.id = res;
                return document;
              });
              return next;
            });
            persistUpload(res);
          })
          .catch((err) => {
            file.error = "Upload failed";
            setIsFailedUploadExist(true);
            setLoading(false);
          });
      });

      const newDocumentIds = await Promise.all(documentPromises);
      setLoading(false);
      setAllDocuments((prev) => {
        const next = prev.map((document: any, index: number) => {
          if (!document.id) {
            document.id = newDocumentIds[index];
          }
          return document as CustomFile;
        });
        return next;
      });
      values.documents = values.documents.filter((document) => {
        if (!document.id) return true;
        else return false;
      });
      setUploading((prev) => {
        let next = [...prev];
        next = next.map(() => false);
        return next;
      });
    },
  });

  const handleClose = () => {
    onClose();
    onAddDocument && onAddDocument();
    resetForm();
    setAllDocuments([]);
    setIsFailedUploadExist(false);
    refetch();
  };

  const persistUpload = async (documentId: string) => {
    if (!documentId) return;
    try {
      switch (values.mediaPurpose) {
        case MediaPurpose.GENERIC_DOCUMENT: {
          await update({
            docGeneric: [...state.docGeneric, documentId],
          });
          break;
        }
        case MediaPurpose.BANK_STATEMENT: {
          await update({
            docBankStatements: [...state.docBankStatements, documentId],
          });
          break;
        }
        case MediaPurpose.TRADE_LICENSE: {
          await update({
            docTradeLicense: documentId,
          });
          break;
        }
        case MediaPurpose.VAT_STATEMENT: {
          await update({
            docVatStatements: [...state.docVatStatements, documentId],
            stage:
              state.annualRevenue >= VAT_LIMIT &&
              !settings.disableVATStatementsUpload
                ? LoanApplicationStage.VAT_STATEMENTS
                : LoanApplicationStage.REVIEW,
          });
          break;
        }
        case MediaPurpose.MEMORANDUM_OF_ASSOCIATION: {
          await update({
            docMemorandumOfAssociation: documentId,
          });
          break;
        }
        case MediaPurpose.SALES_STATEMENT: {
          await update({
            revenueBasedLoan: {
              ...state.revenueBasedLoan,
              salesStatements: [{ documentId }],
            },
          });
          break;
        }
        case MediaPurpose.INVOICE: {
          await update({
            buyNowPayLaterLoan: {
              ...state.buyNowPayLaterLoan,
              salesInvoices: [{ invoiceId: documentId }],
            },
          });
          break;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      !isFailedUploadExist && handleClose();
      refetch();
    }
  };

  const handleFileDrop = async (files: File[]) => {
    if (!files) return;
    if (!values.mediaPurpose) {
      setDropeZoneError(true);
      return;
    }
    if (values.mediaPurpose === MediaPurpose.INVOICE) {
      if (files.length > 1) {
        setCustomErrors({
          multipleFileError: "Cannot upload more than one document",
        });
        return;
      }
      files = [files[0]];
    }

    setDropeZoneError(false);
    setCustomErrors({});
    const filesToAdd: File[] = [];
    files.forEach((file: File) => {
      let isFileAlreadyAdded = allDocuments.some(
        (document) => document.name === file.name
      );
      if (!isFileAlreadyAdded) {
        setUploading((prev) => {
          const next = [...prev];
          next.push(false);
          return next;
        });
        filesToAdd.push(file);
      }
    });
    values.documents = [...filesToAdd, ...values.documents];
    setAllDocuments([...filesToAdd, ...allDocuments]);
  };

  const handleFileClose = async (fileName: string, id?: string) => {
    if (id) {
      deleteMediaById(id)
        .then((res) => {
          setAllDocuments((prev) =>
            prev.filter((document: CustomFile) => document.id !== id)
          );
          setDocumentsIds((prev) =>
            prev.filter((documentId) => documentId !== id)
          );
          values.documents = values.documents.filter(
            (document) => document.id !== id
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (fileName) {
      values.documents = values.documents.filter(
        (document) => fileName !== document.name
      );
      setAllDocuments((prev) => {
        const next = prev.filter((document) => fileName !== document.name);
        return next;
      });
      validateForm();
    }
  };

  const handleRetryUpload = (fileName: string) => {
    const file = values.documents.find(
      (document: File) => document.name === fileName
    );
    if (file) {
      let documentIndex: number;
      allDocuments.forEach((document, index) => {
        if (document.name === fileName) documentIndex = index;
      });
      setUploading((prev) => {
        let next = [...prev];
        next = next.map((item, index) => {
          if (documentIndex === index) return true;
          else return false;
        });
        return next;
      });
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: values.mediaPurpose as MediaPurpose,
        loanApplicationId: state.id,
      })
        .then((res: string) => {
          persistUpload(res);
          setUploading((prev) => prev.map(() => false));
          values.documents = values.documents.filter(
            (document: CustomFile) => document.name !== fileName
          );
          setDocumentsIds((prev) => {
            let next = [...prev];
            next[next.length] = res;
            return next;
          });
          setAllDocuments((prev) => {
            let next = [...prev];
            next = next.map((document, index) => {
              if (documentIndex === index) {
                document.error = "";
                document.id = res;
                return document;
              } else {
                return document;
              }
            });
            return next;
          });
        })
        .catch((err) => {
          setUploading((prev) => prev.map(() => false));
        });
    }
  };

  const handleMediaTypeChange = (mediaType: string) => {
    setFieldValue("mediaPurpose", mediaType);
    setAllDocuments([]);
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title={"Add Documents"}
      className={"cx-w-full"}
    >
      <form onSubmit={handleSubmit}>
        <div
          className={"cx-flex cx-flex-col cx-items-center cx-gap-5 cx-w-full"}
        >
          <div className={"cx-w-full"}>
            <div className="cx-mb-4">
              <Dropzone
                variant="simple"
                extensions={["PDF"]}
                accept={{ "application/pdf": [".pdf"] }}
                onDrop={handleFileDrop}
              />
              {customErrors.multipleFileError && (
                <p className="cx-text-error-regular cx-text-sm cx-mt-2">
                  {customErrors.multipleFileError}
                </p>
              )}
              {dropZoneError && (
                <p className="cx-text-error-regular cx-text-sm cx-mt-2">
                  Please select a document purpose.
                </p>
              )}
            </div>
            <div className="cx-mb-4">
              <Combobox
                placeholder="Select Document Type"
                options={invoiceLabels}
                inputProps={{
                  name: "mediaPurpose",
                  value: invoiceLabels.find(
                    (e) => e.value === values.mediaPurpose
                  ),
                  onBlur: () => setFieldTouched("mediaPurpose"),
                }}
                onChange={handleMediaTypeChange}
                error={
                  touched.mediaPurpose && !!errors.mediaPurpose
                    ? errors.mediaPurpose
                    : ""
                }
              />
            </div>
            {allDocuments.map((document, index) => {
              return (
                <div className="cx-mb-2">
                  <CardList
                    variant={
                      document.error
                        ? CardVariantType.UPLOAD_FAILED
                        : CardVariantType.UPLOADED
                    }
                    title={document.name}
                    description={
                      document.error
                        ? document.error
                        : touched.documents &&
                          values.documents.length &&
                          !!errors.documents?.[index]
                        ? (errors.documents[index] as string)
                        : formatBytes(document.size, 2)
                    }
                    fullWidth
                    icon={
                      <PdfIcon
                        width="100%"
                        height="100%"
                        className="cx-text-brand-primary-regular"
                      />
                    }
                    inputProps={{ accept: ".pdf,application/pdf" }}
                    error={
                      document.error
                        ? true
                        : touched.documents &&
                          !!errors.documents?.[index] &&
                          !!values.documents.length
                    }
                    uploading={uploading[index]}
                    documentId={document.id}
                    handleFileClose={handleFileClose}
                    handleRetryUpload={handleRetryUpload}
                    truncateTitleLength={35}
                    fileNewDesign={true}
                  />
                </div>
              );
            })}
          </div>
          <Button
            label={"Upload"}
            type={"submit"}
            loader={loading ? "left" : undefined}
            disabled={
              isFailedUploadExist
                ? true
                : (!values.documents.length && !allDocuments.length) ||
                  !values.mediaPurpose
            }
            fullWidth
          />
          <Button label={"Cancel"} fullWidth secondary onClick={onClose} />
        </div>
      </form>
    </Modal>
  );
};

export default AddDocumentModal;
