import { getFutureDate, roundTo } from "../../../../utils";
import LoanReviewField from "../LoanReviewField";
import { Divider } from "../../../../components";
import { useLoanMetrics } from "../../../../hooks";
import EditButton from "../EditButton";
import { useState } from "react";
import BusinessLoanModal from "../BusinessLoanModal";
import Transaction from "../../../LoanCalculator/Transaction";
import { useLoanApplication } from "../../../../contexts";

const BuyNowPayLaterLoanReviewSection = () => {
  const [loanCalculatorModalOpen, setLoanCalculatorModalOpen] = useState(false);
  const {
    state: { additionalData },
  } = useLoanApplication();
  const {
    totalPayment,
    borrowedAmount,
    duration,
    interestRate,
    currencyUnit,
    buyNowPayLaterPaymentPerMonth,
  } = useLoanMetrics();

  const generatePaymentSchedule = (): string[] => {
    const initialDate = getFutureDate(2, "days");
    const totalInstallments = (duration.value as unknown as number) + 2;

    return Array.from({ length: totalInstallments - 1 }, (_, index) => {
      const date =
        index === 0
          ? initialDate
          : getFutureDate(1, "months", initialDate.plus({ months: index - 1 }));
      return date.toFormat("dd MMM yyyy");
    });
  };

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
        <div className="cx-text-2xl cx-font-bold">Buy Now Pay Later</div>
        <EditButton onClick={() => setLoanCalculatorModalOpen(true)} />
      </div>

      <div className="cx-flex cx-flex-col sm:cx-flex-row cx-gap-x-10">
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={`${duration.value} ${duration.unit}`}
            label="Loan Duration"
          />
          <Divider />
          {import.meta.env.VITE_HARDCODED_INTEREST_RATE ? (
            <LoanReviewField
              label="Monthly interest rate"
              value={`${import.meta.env.VITE_HARDCODED_INTEREST_RATE}%`}
              description="Approved amount can be up to 90% of your invoice following a successful review of your application."
            />
          ) : (
            <LoanReviewField
              label="Monthly interest rate"
              value={`Pay as low as ${roundTo((interestRate / 12) * 100, 2)}%*`}
              description="*Depends on your loan application and eligibility."
            />
          )}
          {additionalData?.disbursementAccount?.iban && (
            <LoanReviewField
              label={"Disbursement account"}
              value={additionalData?.disbursementAccount?.iban}
            />
          )}
        </div>

        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(
              totalPayment,
              2
            ).toLocaleString()}`}
            label="Total repayment amount"
          />
          <Divider />
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(
              borrowedAmount,
              2
            ).toLocaleString()}*`}
            label="Requested Amount"
            description=""
          />
        </div>
      </div>

      <div className="cx-container cx-flex cx-gap-2 cx-mt-5">
        <p className="cx-text-base cx-text-black">Payment schedule</p>
      </div>

      <div className="cx-w-full">
        {generatePaymentSchedule().map((date, index) => (
          <Transaction
            key={index}
            installmentNo={index}
            isLast={index === duration.value}
            isFirst={index === 0}
            monthlyPayable={buyNowPayLaterPaymentPerMonth}
            currencyUnit={currencyUnit}
            date={date}
            isReviewScreen={true}
          />
        ))}
      </div>

      <div className="cx-h-1/3">
        <BusinessLoanModal
          isOpen={loanCalculatorModalOpen}
          onClose={() => setLoanCalculatorModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default BuyNowPayLaterLoanReviewSection;
