import { DateTime } from "luxon";
import { roundTo } from "../../../../utils";
import LoanReviewField from "../LoanReviewField";
import { Divider } from "../../../../components";
import { useLoanMetrics } from "../../../../hooks";
import EditButton from "../EditButton";
import { useState } from "react";
import BusinessLoanModal from "../BusinessLoanModal";
import { useLoanApplication, useLoanCalculator } from "../../../../contexts";
import {
  LOAN_PRODUCT_DISPLAY_NAME,
  LoanProductType,
} from "../../../../hooks/api/types";
import { useMemo } from "react";

const PAGE_COPY: {
  [key: string]: {
    default: (...args: any[]) => string;
    repayableByDP: (...args: any[]) => string;
  };
} = {
  LOAN_DURATION: {
    default: () => "Loan Duration",
    repayableByDP: () => "Invoice date",
  },
  INTEREST_RATE: {
    default: (interestRate: number) => `Pay as low as ${interestRate}%*`,
    repayableByDP: (interestRate: number) =>
      `Pay from ${interestRate}% per month`,
  },
  REPAYMENT_AMOUNT: {
    default: () => "Total repayment amount",
    repayableByDP: () => "Total payable amount",
  },
  INTEREST_LABEL: {
    default: () => "Monthly interest rate",
    repayableByDP: () => "Monthly fees",
  },
  INVOICE_AMOUNT: {
    default: () => "Requested Amount",
    repayableByDP: () => "Invoice Amount",
  },
  INVOICE_DATE: {
    default: () => "Invoice Due Date",
    repayableByDP: () => "Invoice Due Date",
  },
};

function getCopyText(key: string, repayableByDPFlag?: boolean) {
  return PAGE_COPY[key][repayableByDPFlag ? "repayableByDP" : "default"];
}

const PayableFinancingReviewSection = () => {
  const {
    state: { additionalData },
  } = useLoanApplication();
  const [loanCalculatorModalOpen, setLoanCalculatorModalOpen] = useState(false);
  const {
    payableFinancingProduct: { flags },
    duration,
    interestRate,
    totalPayableFinancingPaymentForSME,
    currencyUnit,
  } = useLoanMetrics();
  const { state } = useLoanCalculator();

  const loanAmount = useMemo(() => {
    let loanAmount = 0;
    if (
      state.type === LoanProductType.PAYABLE_FINANCING &&
      state.payableFinancingLoan.invoices.length > 0
    ) {
      const [invoice] = state.payableFinancingLoan.invoices;
      loanAmount = invoice.amount || 0;
    } else if (state.type === LoanProductType.FIXED_TERM) {
      loanAmount = state.fixedTermLoan.amount;
    }

    return loanAmount;
  }, [state.type, state.payableFinancingLoan.invoices]);

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
        <div className="cx-text-2xl cx-font-bold">
          {LOAN_PRODUCT_DISPLAY_NAME.PAYABLE_FINANCING}
        </div>
        <EditButton onClick={() => setLoanCalculatorModalOpen(true)} />
      </div>
      <div className="cx-flex cx-flex-col sm:cx-flex-row cx-gap-x-10">
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={
              flags.repayableByDP
                ? `${DateTime.fromJSDate(duration.value as Date).toFormat(
                    "dd LLL yyyy"
                  )}`
                : `${duration.value} ${duration.unit}`
            }
            label={getCopyText("LOAN_DURATION", flags.repayableByDP)()}
          />
          <Divider />
          {import.meta.env.VITE_HARDCODED_INTEREST_RATE ? (
            <LoanReviewField
              label="Monthly interest rate"
              value={`${import.meta.env.VITE_HARDCODED_INTEREST_RATE}%`}
            />
          ) : (
            <LoanReviewField
              label={getCopyText("INTEREST_LABEL", flags.repayableByDP)()}
              value={getCopyText(
                "INTEREST_RATE",
                flags.repayableByDP
              )((interestRate / 12) * 100)}
            />
          )}
          {additionalData?.disbursementAccount?.iban && (
            <LoanReviewField
              label={"Disbursement account"}
              value={additionalData.disbursementAccount.iban}
            />
          )}
        </div>
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(loanAmount, 2).toLocaleString()}`}
            label={"Total Invoice Amount"}
          />
          <Divider />
          {totalPayableFinancingPaymentForSME && (
            <LoanReviewField
              value={`${currencyUnit} ${roundTo(
                totalPayableFinancingPaymentForSME,
                2
              ).toLocaleString()}`}
              label={getCopyText("REPAYMENT_AMOUNT", flags.repayableByDP)()}
            />
          )}
        </div>
      </div>
      <BusinessLoanModal
        isOpen={loanCalculatorModalOpen}
        onClose={() => {
          setLoanCalculatorModalOpen(false);
        }}
      />
    </div>
  );
};

export default PayableFinancingReviewSection;
