import { useContext, useState } from "react";
import { useFormik } from "formik";
import { Divider, InputField, StepNextButton } from "../../../components";
import { OTPType, StepperContext, useLoanApplication } from "../../../contexts";
import { useSendEmailOTP, useUser } from "../../../hooks";
import {
  EMAIL_FORM_INITIAL_VALUES,
  EMAIL_FORM_VALIDATION_SCHEMA,
  EmailFormFields,
} from "./service";

const EmailForm = () => {
  const [updating, setUpdating] = useState(false);
  const { previousStep } = useContext(StepperContext);
  const {
    state,
    actions: { update },
  } = useLoanApplication();

  const { mutateAsync: sendOTP } = useSendEmailOTP();
  const { data: user } = useUser();
  const email = state.sourceId ? user?.email ?? state.email : state.email;

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldError,
    handleChange,
    handleSubmit,
  } = useFormik<EmailFormFields>({
    enableReinitialize: true,
    initialValues: {
      ...EMAIL_FORM_INITIAL_VALUES,
      email: email,
    },
    validationSchema: EMAIL_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      setUpdating(true);
      update(
        {
          email: values.email,
        },
        {
          local: true,
        }
      )
        .then(() => {
          sendOTP({
            email: values.email,
          })
            .then(() => {
              update({ otpType: OTPType.EMAIL }, { local: true }).then(() => {
                previousStep();
              });
            })
            .catch((err) => {
              console.log(err);
              setFieldError("email", err.message);
            })
            .finally(() => {
              setUpdating(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setFieldError("email", err.message);
        });
    },
  });

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
        What is your email address?
      </div>
      <div className="cx-w-full cx-mt-10">
        <form onSubmit={handleSubmit}>
          <div className="cx-flex cx-flex-col">
            <div className="cx-flex cx-w-full">
              <div className="cx-ml-1 cx-w-full">
                <InputField
                  label="Email address"
                  inputProps={{
                    name: "email",
                    value: values.email,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  error={!!touched.email && !!errors.email ? errors.email : ""}
                />
              </div>
            </div>
            <div className="cx-mt-10">
              <Divider />
            </div>
            <div className="cx-w-full cx-mt-10">
              <StepNextButton
                label="Verify"
                loading={updating}
                disabled={updating}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailForm;
