import { CredibleLogoLight } from "../../assets/images";
import { useDpConfig } from "../../contexts/dpConfig";
import { ButtonLoaderIcon } from "../../assets/icons";
import { useMicroFlow } from "../../contexts";

type Props = {
  text: string;
};

const Footer: React.FC<Props> = ({ text }) => {
  const {
    state: {
      name,
      uiLocalizationData: { footer },
      loading,
    },
  } = useDpConfig();
  const {
    state: { footerLogo },
  } = useMicroFlow();
  const deducedLogo = (footer?.usePartnerLogo && footer?.logoUrl) || footerLogo;

  return (
    <div className="cx-flex cx-flex-col cx-content-center cx-items-center cx-gap-3 cx-p-4">
      <div className="cx-w-[74px]">
        {loading && !deducedLogo ? (
          <div className="cx-animate-spin cx-w-6 cx-h-6 cx-text-brand-primary-regular">
            <ButtonLoaderIcon />
          </div>
        ) : (
          <img
            src={deducedLogo || CredibleLogoLight}
            alt={deducedLogo ? name : "CredibleX"}
          />
        )}
      </div>
      <div className="cx-max-w-[626px] cx-text-center cx-text-text-tertiary cx-text-xs cx-m-auto">
        {text}
      </div>
    </div>
  );
};

export default Footer;
