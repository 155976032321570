import React, { useEffect, useState } from "react";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import { useDpConfig } from "../../contexts/dpConfig";
import { Checkbox } from "../../components";

type Props = {
  onTermsChange: (checked: boolean) => void;
};

const TermsAndConditions: React.FC<Props> = ({ onTermsChange }) => {
  const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] =
    useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

  const { state } = useDpConfig();

  const handleCheckboxChange = () => {
    const newValue = !termsAndConditionsCheckbox;
    setTermsAndConditionsCheckbox(newValue);
    onTermsChange(newValue);
  };

  useEffect(() => {
    if (state?.settings?.isPartnerTermsAccepted !== undefined) {
      setTermsAndConditionsCheckbox(state.settings.isPartnerTermsAccepted);
      onTermsChange(state.settings.isPartnerTermsAccepted);
    }
  }, [state]);

  return (
    <div className={"cx-w-full"}>
      <div className={"cx-text-neutral-darker cx-text-sm cx-w-full"}>
        By clicking Apply, I acknowledge and agree to be bound by Credible X's{" "}
        <span
          className={"cx-text-primary hover:cx-cursor-pointer"}
          onClick={() => {
            setTermsModalOpen(true);
          }}
        >
          Terms & Conditions
        </span>{" "}
        and{" "}
        <span
          className={"cx-text-primary hover:cx-cursor-pointer"}
          onClick={() => {
            setPrivacyModalOpen(true);
          }}
        >
          Privacy Policy
        </span>
      </div>
      {state.dpTermsUrl && (
        <div
          className={
            "cx-text-neutral-darker cx-mt-2 cx-flex cx-text-sm cx-w-full"
          }
        >
          <Checkbox
            label=""
            variant="slim"
            labelClassnames="cx-text-sm cx-pt-5"
            checked={termsAndConditionsCheckbox}
            onChange={handleCheckboxChange}
          />
          I have read and accept the {state.name}{" "}
          <span
            className={
              "cx-text-brand-primary-regular cx-ml-1 hover:cx-cursor-pointer"
            }
            onClick={() => {
              window.open(`${state.dpTermsUrl}`, "_blank");
            }}
          >
            Terms & Conditions
          </span>
        </div>
      )}
      <>
        <TermsAndConditionsModal
          isOpen={termsModalOpen}
          onClose={() => setTermsModalOpen(false)}
        />
        <PrivacyPolicyModal
          isOpen={privacyModalOpen}
          onClose={() => setPrivacyModalOpen(false)}
        />
      </>
    </div>
  );
};

export default TermsAndConditions;
