import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Footer } from "../components";
import info from "../constants/info.json";
import { useMicroFlow } from "../contexts";
import { ButtonLoaderIcon } from "../assets/icons";

const queryClient = new QueryClient();

const MicroFlows = () => {
  const {
    state: { logo },
  } = useMicroFlow();

  // This logic of setting a default theme is moved inside microflows to avoid flicker in case of theme change
  // useEffect(() => {
  //   setTheme();
  // }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-min-h-screen cx-sticky cx-font-[proxima-nova]">
        <div className="cx-flex cx-justify-center cx-items-center cx-w-full cx-border-b cx-border-stroke-primary">
          <div className="cx-flex cx-justify-between cx-items-center cx-w-full cx-max-w-[960px] cx-px-6 cx-py-4">
            <div className="cx-w-36 cx-h-6 cx-flex cx-justify-center cx-items-center">
              {logo ? (
                <img src={logo} alt="CredibleX" className="cx-max-h-10" />
              ) : (
                <div className="cx-animate-spin cx-w-6 cx-h-6 cx-text-brand-primary-regular">
                  <ButtonLoaderIcon />
                </div>
              )}
            </div>
            <div className="cx-flex cx-justify-center cx-items-center"></div>
          </div>
        </div>
        <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-background-radial-gradient cx-w-full cx-p-8">
          <Outlet />
        </div>
        <div className="cx-p-2">
          <Footer text={info.footer} />
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default MicroFlows;
