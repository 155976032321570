import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";

export const generateUUID = () => {
  return uuidv4();
};

export const toTitleCase = (str: string) => {
  return str?.replace(/[-_]/g, " ")?.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

// get number of days from today
export const differenceDays = (date: string) => {
  return Math.round(Math.abs(DateTime.fromISO(date).diffNow("days").days));
};

export const formatAmount = (amount: number = 0) => {
  return amount.toLocaleString("en-AE");
};

export const formatAmountShort = (amount: number = 0) => {
  if (amount >= 1000000) {
    return `AED ${(amount / 1000000).toFixed(0)}M`;
  } else if (amount >= 1000) {
    return `AED ${(amount / 1000).toFixed(0)}K`;
  } else {
    return `AED ${amount}`;
  }
};

export const formatCurrency = (amount: number = 0) => {
  return `AED ${formatAmount(amount)}`;
};

export const formatCurrencyShort = (amount: number = 0) => {
  return `AED ${formatAmountShort(amount)}`;
};

export const formatPercentage = (percentage: number = 0) => {
  return `${percentage.toFixed(2)}%`;
};

// get truncated string
export const truncate = (
  fullStr: string,
  strLen: number,
  separator?: string
) => {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator ?? "...";

  const sepLen = separator.length,
    charsToShow = strLen - sepLen,
    backChars = 3,
    frontEndIndex = charsToShow - backChars - 1,
    backStartIndex = fullStr.length - backChars - 1;

  return (
    fullStr.substring(0, frontEndIndex) +
    separator +
    fullStr.substring(backStartIndex)
  );
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const validateFileSize = (maxSize: number) => {
  return function (value: any) {
    if (!value) return true;

    const k = 1024;
    const fileSizeInBytes = value.size;
    const maxSizeInBytes = maxSize * k * k;

    return fileSizeInBytes <= maxSizeInBytes;
  };
};

export const generateFile = (fileName: string) => {
  return fileName ? new File([], `${fileName}.pdf`) : null;
};

/**
 * Formats a date string into 'dd MMM, yyyy' format in a specific timezone.
 * If no timezone is provided, it uses the default system timezone.
 *
 * @param dateString - The date string to format.
 * @param outputFormat
 * @returns The formatted date string.
 */
export const formatISOString = (
  dateString: string,
  outputFormat: string = "dd MMM, yyyy"
): string => {
  return DateTime.fromISO(dateString).toFormat(outputFormat);
};

/**
/**
 * Checks if a given date is in the future.
 *
 * @param dateString - The date string to check.
 * @returns True if the date is in the future, false otherwise.
 */
export function isFuture(dateString: string): boolean {
  const date = DateTime.fromISO(dateString);
  const now = DateTime.now();
  return date > now;
}

export function isFutureMonth(date: Date): boolean {
  const now = new Date();
  // Create date objects representing the start of the current and given months
  const currentMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
  const givenMonthStart = new Date(date.getFullYear(), date.getMonth(), 1);

  // Return true if the given month start is after the current month start
  return givenMonthStart > currentMonthStart;
}

export function getHumanReadableMonthShort(dateString: string): string {
  return new Date(dateString).toLocaleString("default", {
    month: "short",
  });
}

export function isFutureYear(referenceDate: Date, date: Date): boolean {
  return date.getFullYear() > referenceDate.getFullYear();
}

/**
 * Calculates a future date by adding a specified time offset to a given date.
 * If no date is provided, the current date and time are used as the base.
 *
 * @param timeOffset - The amount of time to add, specified as a number.
 * @param unit - The unit of time to apply the offset, either 'days' or 'months'.
 * @param fromDate - (Optional) The starting date to which the offset will be applied. If null, the current date and time are used.
 * @returns The calculated future date as a DateTime object.
 */
export function getFutureDate(
  timeOffset: number,
  unit: "days" | "months",
  fromDate?: DateTime
): DateTime {
  const baseDate = fromDate ?? DateTime.now();
  return baseDate.plus({ [unit]: timeOffset });
}

export function truncateText(label: string, maxLength: number) {
  return label.length <= maxLength
    ? label
    : `${label.substring(0, maxLength)}...`;
}

export function isEmptyObject(obj: Object) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}
