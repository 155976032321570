import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useGetSubmittedLoans } from "../../hooks";
import { AdditionalInfo, LoanApplicationStatus } from "../../hooks/api/types";

export enum LoanPaymentStatus {
  PAID = "PAID",
  SCHEDULED = "SCHEDULED",
  OVERDUE = "OVERDUE",
  DUE = "DUE",
  MISSED = "MISSED",
  LATE_FEE_APPLIED = "LATE_FEE_APPLIED",
  PARTIAL_PAYMENT = "PARTIAL_PAYMENT",
  EXCESS_PAYMENT = "EXCESS_PAYMENT",
}

export const statusReadableMap: Record<LoanPaymentStatus, string> = {
  [LoanPaymentStatus.PAID]: "Paid",
  [LoanPaymentStatus.SCHEDULED]: "Scheduled",
  [LoanPaymentStatus.OVERDUE]: "Overdue",
  [LoanPaymentStatus.DUE]: "Due",
  [LoanPaymentStatus.LATE_FEE_APPLIED]: "Late Fee Applied",
  [LoanPaymentStatus.PARTIAL_PAYMENT]: "Partial Payment",
  [LoanPaymentStatus.EXCESS_PAYMENT]: "Excess Payment",
  [LoanPaymentStatus.MISSED]: "Missed",
};

export interface LoanPayment {
  amount: number;
  date: string;
  id: string;
  loanApplicationId: string;
  status: LoanPaymentStatus;
  outstandingAmount: number;
}

interface LoanProductConfig {
  duration: {
    min: number;
    max: number;
    initial: number;
    interval: number;
    unit: string;
  };
  amount: {
    min: number;
    max: number;
    initial: number;
    interval: number;
    unit: string;
  };
  interestRate: {
    value: number;
    period: number;
  };
}

export interface LoanApplication {
  id: string;
  companyId: string;
  distributionPartnerId: string;
  userId: string;
  stage: string;
  loanProductId: string;
  loanInput: {
    amount: number;
    duration: number;
    loanType: string;
  };
  loanProduct: {
    id: string;
    displayName: string;
    type: string;
    config: LoanProductConfig;
  };
  loanPayments: Array<LoanPayment>;
  status: LoanApplicationStatus;
  createdAt: string;
  loanType: string;
  additionalInfo: AdditionalInfo[];
  externalId: number;
  nextPaymentDate: string;
  amount?: number;
  paidAmount?: number;
  remainingAmount?: number;
  loanOfferInterestRate?: number;

  [key: string]: any;
}

type LoanDashboardContextValues = {
  state: {
    loans: Array<LoanApplication>;
    loading: boolean;
    currentLoanApplicationId: string;
    currentPaymentId: string;
    showPaymentDetails: boolean;
    showPaymentSchedule: boolean;
    showPaymentHistory: boolean;
  };
  actions: {
    update: (
      payload: Partial<LoanDashboardContextValues["state"]>
    ) => Promise<any>;
    refetch: () => Promise<any>;
  };
};

type LoanDashboardContextAction = {
  type: "UPDATE";
  payload: Partial<LoanDashboardContextValues["state"]>;
};

const INITIAL_STATE: LoanDashboardContextValues["state"] = {
  loans: [],
  loading: false,
  currentLoanApplicationId: "",
  currentPaymentId: "",
  showPaymentSchedule: false,
  showPaymentHistory: false,
  showPaymentDetails: false,
};

const LoanDashboardContext = createContext<LoanDashboardContextValues>({
  state: INITIAL_STATE,
  actions: {
    update: async () => {},
    refetch: async () => {},
  },
});

const reducer = (
  state: LoanDashboardContextValues,
  action: LoanDashboardContextAction
): LoanDashboardContextValues => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        state: {
          ...state.state,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export const LoanDashboardProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    state: INITIAL_STATE,
    actions: {
      update: async () => {},
      refetch: async () => {},
    },
  });

  const { mutateAsync: getApplications } = useGetSubmittedLoans();

  function fetchAndPopulateDashboardContext() {
    const initialize = async () => {
      const res = await getApplications();
      dispatch({
        type: "UPDATE",
        payload: { loans: res?.loanApplications },
      });
    };
    initialize().catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchAndPopulateDashboardContext();
  }, []);

  const actions = useMemo(() => {
    return {
      update: async (payload: Partial<LoanDashboardContextValues["state"]>) => {
        dispatch({ type: "UPDATE", payload });
      },
      refetch: async () => {
        fetchAndPopulateDashboardContext();
      },
    };
  }, [dispatch]);

  const contextValue = useMemo(() => {
    return { state: state.state, actions };
  }, [state.state]);

  return (
    <LoanDashboardContext.Provider value={contextValue}>
      {children}
    </LoanDashboardContext.Provider>
  );
};

export const useLoanDashboard = () => useContext(LoanDashboardContext);
