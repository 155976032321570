import { useState } from "react";
import { Button, Divider } from "../../../../components";
import { useLoanApplication } from "../../../../contexts";
import { useMedia } from "../../../../hooks";
import {
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../../../hooks/api/types";
import { PlusIcon } from "../../../../assets/icons";
import { AddDocumentModal } from "../../FinancialInformation";
import AttachedDocumentCard from "./AttachedDocumentCard";
import { useDpConfig } from "../../../../contexts/dpConfig";

const DocumentsReviewSection = () => {
  const [bankStatementsUploading, setBankStatementsUploading] = useState<
    boolean[]
  >([]);
  const [salesStatementUploading, setSalesStatementUploading] = useState<
    boolean[]
  >([]);
  const [salesInvoiceUploading, setSalesInvoiceUploading] = useState<boolean[]>(
    []
  );
  const [tradeLicenseUploading, setTradeLicenseUploading] = useState<boolean[]>(
    []
  );
  const [moaUploading, setMoaUploading] = useState<boolean[]>([]);
  const [poaUploading, setPoaUploading] = useState<boolean[]>([]);
  const [vatStatementsUploading, setVatStatementsUploading] = useState<
    boolean[]
  >([]);
  const [genericDocUploading, setGenericDocUploading] = useState<boolean[]>([]);
  const loanApplicationData = useLoanApplication();
  const [addDocumentModalOpen, setAddDocumentModalOpen] = useState(false);
  const {
    state: { uiLocalizationData },
  } = useDpConfig();

  const { upload } = useMedia();

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
        <div className="cx-text-2xl cx-font-bold">Attached Documents</div>
        <Button
          label={
            <div className="cx-flex cx-items-center">
              <PlusIcon className="cx-mr-2" />
              <div>Add</div>
            </div>
          }
          slim={true}
          onClick={() => {
            setAddDocumentModalOpen(true);
          }}
        />
      </div>

      <Divider />
      {/* <div className="cx-mt-6">
        <div className="cx-text-lg cx-font-semibold">Emirates ID</div>
        <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
          <AttachedDocumentCard document={{ label: "Front" }} />
          <AttachedDocumentCard document={{ label: "Back" }} />
        </div>
        <Divider />
      </div> */}

      {!!loanApplicationData.state.docTradeLicenses?.length && (
        <div className="cx-mt-6">
          <div className="cx-text-lg cx-font-semibold">Trade License</div>
          <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
            {loanApplicationData.state.docTradeLicenses.map((doc, idx) => (
              <AttachedDocumentCard
                key={doc}
                document={{
                  id: doc,
                }}
                loading={tradeLicenseUploading[idx]}
                showTitle={false}
                onChange={async (file) => {
                  if (!file) return;
                  setTradeLicenseUploading((prev) => {
                    const newPrev = [...prev];
                    newPrev[idx] = true;
                    return newPrev;
                  });
                  const id = await upload({
                    file,
                    type: MediaType.DOCUMENT,
                    purpose: MediaPurpose.TRADE_LICENSE,
                    loanApplicationId: loanApplicationData.state.id,
                  });

                  const newStatements = [
                    ...loanApplicationData.state.docTradeLicenses,
                  ];
                  newStatements[idx] = id;

                  await loanApplicationData.actions
                    .update({
                      docTradeLicenses: newStatements,
                    })
                    .catch((err) => {
                      console.log(err);
                    })
                    .finally(() => {
                      setTradeLicenseUploading((prev) => {
                        const newPrev = [...prev];
                        newPrev[idx] = false;
                        return newPrev;
                      });
                    });
                }}
              />
            ))}
          </div>
          {!!loanApplicationData.state.docTradeLicenses?.length && <Divider />}
        </div>
      )}
      {!!loanApplicationData.state.docMOAs?.length && (
        <div className="cx-mt-6">
          <div className="cx-text-lg cx-font-semibold">
            Memorandum Of Association
          </div>
          <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
            {loanApplicationData.state.docMOAs.map((doc, idx) => (
              <AttachedDocumentCard
                key={doc}
                document={{
                  id: doc,
                }}
                loading={moaUploading[idx]}
                showTitle={false}
                onChange={async (file) => {
                  if (!file) return;
                  setMoaUploading((prev) => {
                    const newPrev = [...prev];
                    newPrev[idx] = true;
                    return newPrev;
                  });
                  const id = await upload({
                    file,
                    type: MediaType.DOCUMENT,
                    purpose: MediaPurpose.MEMORANDUM_OF_ASSOCIATION,
                    loanApplicationId: loanApplicationData.state.id,
                  });

                  const newStatements = [
                    ...loanApplicationData.state.docMemorandumOfAssociation,
                  ];
                  newStatements[idx] = id;

                  await loanApplicationData.actions
                    .update({
                      docMOAs: newStatements,
                    })
                    .catch((err) => {
                      console.log(err);
                    })
                    .finally(() => {
                      setMoaUploading((prev) => {
                        const newPrev = [...prev];
                        newPrev[idx] = false;
                        return newPrev;
                      });
                    });
                }}
              />
            ))}
          </div>
          {!!loanApplicationData.state.docMOAs?.length && <Divider />}
        </div>
      )}

      {!!loanApplicationData.state.docPOAs?.length && (
        <div className="cx-mt-6">
          <div className="cx-text-lg cx-font-semibold">Power Of Attorney</div>
          <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
            {loanApplicationData.state.docPOAs.map((doc, idx) => (
              <AttachedDocumentCard
                key={doc}
                document={{
                  id: doc,
                }}
                loading={poaUploading[idx]}
                showTitle={false}
                onChange={async (file) => {
                  if (!file) return;
                  setPoaUploading((prev) => {
                    const newPrev = [...prev];
                    newPrev[idx] = true;
                    return newPrev;
                  });
                  const id = await upload({
                    file,
                    type: MediaType.DOCUMENT,
                    purpose: MediaPurpose.POWER_OF_ATTORNEY,
                    loanApplicationId: loanApplicationData.state.id,
                  });

                  const newStatements = [...loanApplicationData.state.docPOAs];
                  newStatements[idx] = id;

                  await loanApplicationData.actions
                    .update({
                      docPOAs: newStatements,
                    })
                    .catch((err) => {
                      console.log(err);
                    })
                    .finally(() => {
                      setPoaUploading((prev) => {
                        const newPrev = [...prev];
                        newPrev[idx] = false;
                        return newPrev;
                      });
                    });
                }}
              />
            ))}
          </div>
          {!!loanApplicationData.state.docPOAs?.length && <Divider />}
        </div>
      )}

      <div className="cx-mt-6">
        {loanApplicationData.state.loanType ===
          LoanProductType.REVENUE_BASED_FINANCING &&
          loanApplicationData?.state?.revenueBasedLoan?.salesStatements
            ?.length > 0 && (
            <div className="cx-mt-6">
              <div className="cx-text-lg cx-font-semibold">Sales Statement</div>
              <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
                {loanApplicationData.state.revenueBasedLoan.salesStatements.map(
                  (doc, idx) => (
                    <AttachedDocumentCard
                      key={doc.documentId}
                      document={{ id: doc.documentId }}
                      loading={salesStatementUploading[idx]}
                      showTitle={false}
                      onChange={async (file) => {
                        if (!file) return;
                        setSalesStatementUploading((prev) => {
                          const newPrev = [...prev];
                          newPrev[idx] = true;
                          return newPrev;
                        });
                        const id = await upload({
                          file,
                          type: MediaType.DOCUMENT,
                          purpose: MediaPurpose.SALES_STATEMENT,
                          loanApplicationId: loanApplicationData.state.id,
                        });

                        const newStatements = [
                          ...loanApplicationData.state.revenueBasedLoan
                            .salesStatements,
                        ];
                        newStatements[idx].documentId = id;

                        await loanApplicationData.actions
                          .update({
                            revenueBasedLoan: {
                              ...loanApplicationData.state.revenueBasedLoan,
                              salesStatements: newStatements,
                            },
                          })
                          .catch((err) => {
                            console.log(err);
                          })
                          .finally(() => {
                            setSalesStatementUploading((prev) => {
                              const newPrev = [...prev];
                              newPrev[idx] = false;
                              return newPrev;
                            });
                          });
                      }}
                    />
                  )
                )}
              </div>
              <Divider />
            </div>
          )}
      </div>
      <div className="cx-mt-6">
        {loanApplicationData.state.loanType === LoanProductType.BNPL &&
          loanApplicationData?.state?.buyNowPayLaterLoan?.salesInvoices
            ?.length && (
            <div className="cx-mt-6">
              <div className="cx-text-lg cx-font-semibold">
                {uiLocalizationData.reviewScreen.invoiceLabel}
              </div>
              <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
                {loanApplicationData.state.buyNowPayLaterLoan.salesInvoices.map(
                  (doc, idx) => (
                    <AttachedDocumentCard
                      key={doc.invoiceId}
                      document={{ id: doc.invoiceId }}
                      loading={salesInvoiceUploading[idx]}
                      showTitle={false}
                      onChange={async (file) => {
                        if (!file) return;
                        setSalesInvoiceUploading((prev) => {
                          const newPrev = [...prev];
                          newPrev[idx] = true;
                          return newPrev;
                        });
                        const id = await upload({
                          file,
                          type: MediaType.DOCUMENT,
                          purpose: MediaPurpose.INVOICE,
                          loanApplicationId: loanApplicationData.state.id,
                        });

                        const newInvoices = [
                          ...loanApplicationData.state.buyNowPayLaterLoan
                            .salesInvoices,
                        ];
                        newInvoices[idx].invoiceId = id;

                        await loanApplicationData.actions
                          .update({
                            buyNowPayLaterLoan: {
                              ...loanApplicationData.state.buyNowPayLaterLoan,
                              salesInvoices: newInvoices,
                            },
                          })
                          .catch((err) => {
                            console.log(err);
                          })
                          .finally(() => {
                            setSalesInvoiceUploading((prev) => {
                              const newPrev = [...prev];
                              newPrev[idx] = false;
                              return newPrev;
                            });
                          });
                      }}
                    />
                  )
                )}
              </div>
              <Divider />
            </div>
          )}
      </div>
      <div className="cx-mt-6">
        {!!loanApplicationData.state.docBankStatements.filter(Boolean)
          .length && (
          <div className="cx-mt-6">
            <div className="cx-text-lg cx-font-semibold">Bank Statement</div>
            <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
              {loanApplicationData.state.docBankStatements
                .filter(Boolean)
                .map((doc, idx) => (
                  <AttachedDocumentCard
                    key={doc}
                    document={{ id: doc }}
                    loading={bankStatementsUploading[idx]}
                    showTitle={true}
                    onChange={async (file) => {
                      if (!file) return;
                      setBankStatementsUploading((prev) => {
                        const newPrev = [...prev];
                        newPrev[idx] = true;
                        return newPrev;
                      });
                      const id = await upload({
                        file,
                        type: MediaType.DOCUMENT,
                        purpose: MediaPurpose.BANK_STATEMENT,
                        loanApplicationId: loanApplicationData.state.id,
                      });

                      const newStatements = [
                        ...loanApplicationData.state.docBankStatements,
                      ];
                      newStatements[idx] = id;

                      await loanApplicationData.actions
                        .update({
                          docBankStatements: newStatements,
                        })
                        .catch((err) => {
                          console.log(err);
                        })
                        .finally(() => {
                          setBankStatementsUploading((prev) => {
                            const newPrev = [...prev];
                            newPrev[idx] = false;
                            return newPrev;
                          });
                        });
                    }}
                  />
                ))}
            </div>
            <Divider />
          </div>
        )}
      </div>
      {!!loanApplicationData.state.docVatStatements?.length && (
        <div className="cx-mt-6">
          <div className="cx-text-lg cx-font-semibold">VAT Statements</div>
          <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
            {loanApplicationData.state.docVatStatements.map((doc, idx) => (
              <AttachedDocumentCard
                key={doc}
                document={{
                  id: doc,
                }}
                loading={vatStatementsUploading[idx]}
                showTitle={true}
                onChange={async (file) => {
                  if (!file) return;
                  setVatStatementsUploading((prev) => {
                    const newPrev = [...prev];
                    newPrev[idx] = true;
                    return newPrev;
                  });

                  const id = await upload({
                    file,
                    type: MediaType.DOCUMENT,
                    purpose: MediaPurpose.VAT_STATEMENT,
                    loanApplicationId: loanApplicationData.state.id,
                  });

                  const newStatements = [
                    ...loanApplicationData.state.docVatStatements,
                  ];
                  newStatements[idx] = id;

                  await loanApplicationData.actions
                    .update({
                      docVatStatements: newStatements,
                    })
                    .catch((err) => {
                      console.log(err);
                    })
                    .finally(() => {
                      setVatStatementsUploading((prev) => {
                        const newPrev = [...prev];
                        newPrev[idx] = false;
                        return newPrev;
                      });
                    });
                }}
              />
            ))}
          </div>
          {!!loanApplicationData.state.docGeneric?.length && <Divider />}
        </div>
      )}

      {!!loanApplicationData.state.docGeneric?.length && (
        <div className="cx-mt-6">
          <div className="cx-text-lg cx-font-semibold">
            Additional Financial Documents
          </div>
          <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
            {loanApplicationData.state.docGeneric.map((doc, idx) => (
              <AttachedDocumentCard
                key={doc}
                document={{
                  id: doc,
                }}
                accept="*"
                loading={genericDocUploading[idx]}
                showTitle={true}
                onChange={async (file) => {
                  if (!file) return;

                  setGenericDocUploading((prev) => {
                    const newPrev = [...prev];
                    newPrev[idx] = true;
                    return newPrev;
                  });

                  const id = await upload({
                    file,
                    type: MediaType.DOCUMENT,
                    purpose: MediaPurpose.GENERIC_DOCUMENT,
                    loanApplicationId: loanApplicationData.state.id,
                  });

                  const newGenericDocumentsList = [
                    ...loanApplicationData.state.docGeneric,
                  ];
                  newGenericDocumentsList[idx] = id;

                  await loanApplicationData.actions
                    .update({
                      docGeneric: newGenericDocumentsList,
                    })
                    .catch((err) => {
                      console.log(err);
                    })
                    .finally(() => {
                      setGenericDocUploading((prev) => {
                        const newPrev = [...prev];
                        newPrev[idx] = false;
                        return newPrev;
                      });
                    });
                }}
              />
            ))}
          </div>
        </div>
      )}
      <AddDocumentModal
        open={addDocumentModalOpen}
        onClose={() => setAddDocumentModalOpen(false)}
      />
    </div>
  );
};

export default DocumentsReviewSection;
